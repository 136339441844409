<template>
    <div class="l-main">
        <div class="l-main-content">
            <div class="row bg-white-shadow border-bottom border-top">
                <div class="col-4">
                    <h3>Summary</h3>
                </div>
                <div class="col-8 text-right">
    
                    <select class="select sm hidden" v-model="days" @change="searchRecords">
    
                        <option value="0">Today</option>
                        <option value="7"> Last 7 Days</option>
                        <option value="30"> Last 30 Days</option>
                    </select>
                    <div class="buttons small">
    
                        <button class="button" @click="searchRecords(0)" :class="{'selected': days == 0}">Today</button>
                        <button class="button" @click="searchRecords(7)" :class="{'selected': days == 7}">7d</button>
                        <button class="button" @click="searchRecords(30)" :class="{'selected': days == 30}">30d</button>
                        <span> Filter By Date: </span>
                        <date-picker v-model="time3" range @change="searchRecords"></date-picker>
                    </div>
                </div>
                <div class="rsum">
                    <div class="col-4 sum red">
                        <div class="h">Total Active Events</div>
                        <div class="t">{{eventsTicket | formatNumber}}</div>
                        <div class="s hidden"><i class="fa fa-caret-up"></i> %</div>
                    </div>
                    <div class="col-4 sum dark">
                        <div class="h">Total Ticket Purchased</div>
                        <div class="t">{{purchaseTicket | formatNumber}}</div>
                    </div>
                    <div class="col-4 sum yellow">
                        <div class="h">Total Ticket Redeemed</div>
                        <div class="t">{{redeemedTickets | formatNumber}}</div>
                    </div>
    
                </div>
                <div class="rsum">
                    <div class="col-6 sum teal">
                        <div class="h">Total Members</div>
                        <div class="t">{{members | formatNumber}}</div>
                    </div>
                    <div class="col-6 sum orange">
                        <div class="h">Desposit</div>
                        <div class="t">KES {{deposit | formatNumber}}</div>
                    </div>
                </div>
            </div>
    
            <div class="row bg-white-shadow border-bottom border-top">
    
                <div class="col-6">
                    <h3>Ticket Types</h3>
                    <small class="text-grey-light">Which ticket types are being purchases</small>
                    <div class="rbars" v-for="(types, index) in ticketTypes" :key="index+1">
                        <div class="bar">
                            <div class="bg" :style="{width: (types.totalPurchase / types.totalTickets * 100) + '%' }"></div>
                            <div class="txt">{{types.ticket_type}} <br /><small>KES {{types.amount | formatNumber}}</small> | <small>Ticket Purchased {{types.totalPurchase | formatNumber}} / {{types.totalTickets}}</small></div>
                            <div class="per">{{(types.totalPurchase / types.totalTickets * 100) | formatPercent}}% </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <h3>Ticket Purchase</h3>
                    <small>Daily trend</small>
                    <div class="p-top">
                        <loader v-show='loading' style="margin-left: 30px;" />
                        <canvas id="purchase-chart" height="300"></canvas></div>
                </div>
            </div>
    
    
            <div class="row bg-white-shadow border-bottom border-top">
                <div class="col">
                    <h3>Customers</h3>
                </div>
                <div class="row">
                    <div class="col-6">
                        <h4>Returning</h4>
                        <h3 style="color: #FFC300"><strong>{{returnCustomer}}</strong></h3>
                        <small>{{percentReturnCustomer}}%</small>
    
                        <div class="divider"></div>
    
                        <h4>New</h4>
                        <h3 style="color: #FF5733"><strong>{{newCustomer}}</strong></h3>
                        <small>{{percentNewCustomer}}%</small>
                    </div>
                    <div class="col-6 text-right">
                        <canvas id="customers-chart" width="200" height="130"></canvas>
                    </div>
                    <div class="col"></div>
                </div>
            </div>
    
        </div>
    </div>
</template>

<script>
import Chart from "chart.js";
import store from "../store";
import $ from 'jquery';
import numeral from "numeral";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import loader from "@/components/loader"


export default {
    data() {
        return {
            selectedTab: 'summary',
            eventsTicket: 0,
            purchaseTicket: 0,
            redeemedTickets: 0,
            members: 0,
            customers: 0,
            newCustomer: 0,
            percentNewCustomer: 0,
            returnCustomer: 0,
            percentReturnCustomer: 0,
            time3: "",
            startDate: "",
            endDate: "",
            ticketTypes: [],
            days: 7,
            deposit: 0,
            loading: true
        }
    },
    components: {
        DatePicker,
        loader,
        
    },
    mounted() {

        let vm = this;
        vm.getGraphRegistration();
        vm.getCustomers();
        vm.getsummary();
        vm.getTicketTypes()
        if (!this.$cookies.get("key")) {
            this.$router.push({
                name: 'login'
            });
        }

    },
    methods: {
        format_date(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        searchRecords(v) {
            let vm = this;
            if (vm.time3 != "") {
                vm.days = 0;
                var timString = vm.time3 + "";
                var arrayTime = timString.split(",");
                vm.startDate = vm.format_date(arrayTime[0]) + " 00:00:00";
                vm.endDate = vm.format_date(arrayTime[1]) + " 23:59:59";

            } else {
                vm.days = v;
            }
            vm.eventsTicket = 0;
            vm.purchaseTicket = 0;
            vm.redeemedTickets = 0;
            vm.members = 0;
            vm.customers = 0;
            vm.newCustomer = 0;
            vm.percentNewCustomer = 0;
            vm.returnCustomer = 0;
            vm.percentReturnCustomer = 0;
            vm.ticketTypes = []
          
            vm.getGraphRegistration();
            vm.getCustomers();
            vm.getsummary();
            vm.getTicketTypes()

        },
        getsummary() {
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/summary",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    days: vm.days,
                    start: vm.startDate,
                    end: vm.endDate,
                    source: "WEB"
                }),
                success: function(response, status, jQxhr) {
                    var res = response.data.data;
                    vm.purchaseTicket = Number(response.data.data.totalTickets);
                    vm.redeemedTickets = Number(response.data.data.totalRedemmed);
                    vm.eventsTicket = Number(response.data.data.activeEvent);
                    vm.members = Number(response.data.data.totalCustomer);
                    vm.deposit = Number(response.data.data.totalDeposit)
                },
                error: function(jQxhr, status, error) {

                }
            });
        },
        getCustomers() {
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/customer",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    days: vm.days,
                    start: vm.startDate,
                    end: vm.endDate,
                    source: "WEB"
                }),
                success: function(response, status, jQxhr) {
                    var customers = response.data.data;
                    vm.newCustomer = vm.numberWithCommas(customers[0].totalNew);
                    vm.returnCustomer = vm.numberWithCommas(customers[0].totalreturn);
                    var total = Number(customers[0].totalNew) + Number(customers[0].totalreturn);
                    vm.percentNewCustomer = (((Number(customers[0].totalNew)) / total) * 100).toFixed(2);
                    vm.percentReturnCustomer = ((Number(customers[0].totalreturn) / total) * 100).toFixed(2);

                    var ctx = $('#customers-chart');
                    var customersChart = new Chart(ctx, {
                        type: 'pie',
                        data: {
                            datasets: [{
                                data: [vm.percentNewCustomer, vm.percentReturnCustomer],
                                backgroundColor: [
                                    '#FF5733',
                                    '#FFC300',
                                ]
                            }],
                            labels: ['New', 'Returning'],
                        },
                        options: {
                            maintainAspectRatio: false,
                            responsive: true,
                            legend: {
                                display: false
                            }
                        }
                    });
                },
                error: function(jQxhr, status, error) {}
            });
        },
        getTicketTypes() {
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/tickets/types",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    days: vm.days,
                    limit: 10,
                    start: vm.startDate,
                    end: vm.endDate,
                    source: 'WEB'
                }),
                success: function(response, status, jQxhr) {
                    var res = response.data.data;
                    vm.ticketTypes = res
                },
                error: function(jQxhr, status, error) {

                }
            });
        },
        getGraphRegistration() {
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/graph",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    days: vm.days,
                    start: vm.startDate,
                    end: vm.endDate,
                    source: "WEB"
                }),
                success: function(response, status, jQxhr) {
                    let yData = [];
                    let xDate = [];
                    let result = response.data.data;
                    vm.loading = false

                    result.forEach(function(data) {
                        yData.push(data["COUNT"]);
                        xDate.push(data["Date"]);
                    });
                    var ctx = $('#purchase-chart');
                    var customersChart = new Chart(ctx, {
                        type: 'bar',
                        data: {
                            datasets: [{
                                data: yData,
                                backgroundColor: "#FFA500"
                            }],
                            labels: xDate,
                        },
                        options: {
                            maintainAspectRatio: false,
                            responsive: true,
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }
                    });
                },
                error: function(jQxhr, status, error) {}
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    filters: {
        formatAmount(value) {
            return numeral(value).format("0,0.00");
        },
        formatPercent(value) {
            return numeral(value).format("0,0.00");
        },
        formatNumber(value) {
            return numeral(value).format("0,0");
        }
    }
}
</script>
