<template>
    <div class="l-main">
        <div class="l-main-content">
            <div class="l-form row bg-white-shadow  border-top" v-if="eventData.hasMultipleShow == 1">
                <div class="col-8">
                    <h3>Summary Event : {{ eventData.eventName }}</h3>
                </div>
                <div class="col-4 text-right">
    
                </div>
                <div class="col-6">
                    <label>Select Shows</label>
                    <select v-model="event_show_id" @change="getEventShowsVenue(event_show_id)">
                                                                <option value>Select Event Shows</option>
                                                                <option v-for="eventShow in eventShows" :key="eventShow.event_show_id" :value="eventShow.event_show_id">{{ eventShow.show }}</option>
                                                            </select>
                </div>
                <div class="col-6">
                    <label>Select Venue</label>
                    <select v-model="event_show_venue_id">
                                                                <option value>Select Event Shows</option>
                                                                <option v-for="eventShowVe in eventShowsVenue" :key="eventShowVe.event_show_venue_id" :value="eventShowVe.event_show_venue_id">{{ eventShowVe.venue }}</option>
                                                            </select>
                </div>
                <div class="col-4">
                    <button class="button primary" id="button-export" @click="openCreateShows()"><i class="fa fa-plus" aria-hidden="true"></i> Add Shows</button>
                </div>
                <div class="col-4"></div>
                <div class="col-4">
                    <button class="button secondary" id="button-export" @click="viewEvent()"><i class="fa fa-eye" aria-hidden="true"></i> View Event</button>
                </div>
            </div>
            <div class="l-form row bg-white-shadow  border-top" v-if="eventData.hasMultipleShow != 1">
                <div class="col-8">
                    <h3>Summary Event : {{ eventData.eventName }}</h3>
                </div>
                <div class="col-4">
                    <button class="button secondary" id="button-export" @click="viewEvent()"><i class="fa fa-eye" aria-hidden="true"></i> View Event</button>
                </div>
    
            </div>
            <div class="row bg-white-shadow border-bottom border-top">
    
                <div class="rsum">
                    <div class="col-4 sum yellow">
                        <div class="h">Total Tickets</div>
                        <div class="t">KES {{amount | formatNumber}}</div>
                        <div class="s hidden"><i class="fa fa-caret-up"></i> %</div>
                    </div>
                    <div class="col-4 sum orange">
                        <div class="h">Total Ticket Purchase</div>
                        <div class="t">{{ticketPurchase | formatNumber}}</div>
                    </div>
                    <div class="col-4 sum teal">
                        <div class="h">Total Ticket Redmeed</div>
                        <div class="t">{{ticketRedmeed | formatNumber}}</div>
                    </div>
                </div>
            </div>
    
    
            <div class="row bg-white-shadow border-bottom border-top">
                <div class="col">
                    <div class="col-4">
                        <h3>Ticket Purchased</h3>
                        <small>Daily trend</small>
                    </div>
                    <div class="col-8 text-right">
    
    
                        <div class="buttons small">
    
                            <button class="button" @click="searchRecords(0)" :class="{'selected': days == 0}">Today</button>
                            <button class="button" @click="searchRecords(7)" :class="{'selected': days == 7}">7d</button>
                            <button class="button" @click="searchRecords(30)" :class="{'selected': days == 30}">30d</button>
    
                        </div>
                    </div>
                    <div class="p-top"><canvas id="purchase-chart" height="300"></canvas></div>
                </div>
            </div>
            <div class="bg-white-shadow border-bottom border-top">
                <div class="row">
                    <div class="col-6">
                        <h3>Tickets Types</h3>
                        <small class="text-grey-light">Ticket Types Purchased</small>
                    </div>
                    <div class="col-6 text-right">
                        <button class="button primary" id="button-export" @click="addTicketType">Add Ticket Type </button>
                    </div>
                </div>
                <div class="row" v-for="(types, index) in ticketTypes" :key="index+1">
                    <div class="col-8">
                        <div class="rbars">
                            <div class="bar">
                                <div class="bg" :style="{width: (types.ticket_purchased / types.total_tickets * 100) + '%' }"></div>
                                <div class="txt">{{types.ticket_type}} <br /><small>KES {{types.amount | formatNumber}}</small></div>
                                <div class="per">{{(types.ticket_purchased / types.total_tickets * 100) | formatPercent}}% </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div><small>Status <strong style="float: right;">{{types.status | formatStatus}}</strong></small></div>
                        <div><small>Ticket Purchased: <strong style="float: right;">{{types.ticket_purchased | formatNumber}} / {{types.total_tickets}}</strong></small></div>
                        <div><small>Issued Complimentaries: <strong style="float: right;">{{types.issued_complimentary | formatNumber}}</strong></small></div>
                        <div><small>Total Amount: <strong style="float: right;">KES {{(types.amount * types.ticket_purchased) | formatNumber}}</strong></small></div>
                        <small style="float: right;"><button class="button primary" id="button-export" @click="openEditTickets(types)" style="margin-left: 5px;"><i class="fa fa-edit" aria-hidden="true"></i></button></small>
                        <small style="float: right;"><button class="button seondary" id="button-export" @click="openComplimentary(types)"><i class="fas fa-share-square" aria-hidden="true"></i></button></small>
                    </div>
                </div>
            </div>
    
            <modal name="example" :width="600" :height="320" :adaptive="true">
                <div class="row">
                    <div class="col">
    
                        <div class="l-form row">
                            <div class="col">
                                <div class="col-6">
                                    <h4>Ticket Type</h4>
                                    <select v-model="ticketTypeSelect">
                                                                                <option value>Select Ticket Type</option>
                                                                                <option v-for="s_id in ticketTypesList" :key="s_id.typeId" :value="s_id.typeId">{{s_id.ticket_type}}</option>
                                                                            </select>
                                </div>
                                <div class="col-6">
                                    <h4>Total Tickets</h4>
                                    <input type="text" v-model="totalTickets" placeholder="e.g VIP">
                                </div>
    
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h4>Group Ticket</h4>
                                    <input type="number" v-model="groupTickets">
                                </div>
                                <div class="col-6">
                                    <h4>Complimentary</h4>
                                    <input type="number" v-model="total_complimentary">
                                </div>
                            </div>
                            <div class="col">
                                <h4>Amount</h4>
                                <input type="number" v-model="amount">
                            </div>
    
    
                            <div class="col">
                                <button class="button primary" id="createType" @click="createEventTicketTypes()">Create</button>
    
                            </div>
                        </div>
                    </div>
                </div>
            </modal>
            <vue-modal-2 width="500" name="modal_create_shows" @on-close="closeShows" :headerOptions="{
                                                    title: 'Create Shows: ',
                                                }" :footerOptions="{
                                                    btn1: 'Cancel',
                                                    btn2: 'Update',
                                                    btn1Style: {
                                                    backgroundColor: 'red',
                                                    color:'white'
                                                    },
                                                    btn2OnClick: () => {
                                                        createShows();
                                                    },
                                                    btn1OnClick: () => {
                                                    $vm2.close('modal_create_shows');
                                                    },
                                                }">
                <div class="l-form row">
                    <div class="col">
                        <div class="col-6">
                            <h4>Show Name</h4>
                            <input type="text" v-model="showName" placeholder="e.g VIP">
                        </div>
                        <div class="col-6">
                            <h4>Venue</h4>
                            <vue-tags-input v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" placeholder="Type Venue and press enter" />
                        </div>
    
                    </div>
                    <div class="col">
    
                        <h3>Show Date</h3>
                        <date-picker v-model="eventRange" type="datetime" range class="input inline"></date-picker>
    
    
                    </div>
    
                </div>
    
    
            </vue-modal-2>
            <vue-modal-2 width="500" name="modal_edit" @on-close="close" :headerOptions="{
                                                    title: 'Edit Tickets: '+selectedTickets.ticket_type,
                                                }" :footerOptions="{
                                                    btn1: 'Cancel',
                                                    btn2: 'Update',
                                                    btn1Style: {
                                                    backgroundColor: 'red',
                                                    color:'white'
                                                    },
                                                    btn2OnClick: () => {
                                                        updateTickets();
                                                    },
                                                    btn1OnClick: () => {
                                                    $vm2.close('modal_edit');
                                                    },
                                                }">
                <div class="row">
                    <div class="col">
    
                        <div class="l-form row">
                            <div class="col">
                                <div class="col-6">
                                    <h4>Status</h4>
                                    <select v-model="selectedTickets.status">
                                                            <option value="1">Active</option>
                                                            <option value="2">Pending</option>
                                                            <option value="3">Close</option>
                                                        </select>
                                </div>
    
                                <div class="col-6">
                                    <h4>Total Tickets</h4>
                                    <input type="text" v-model="selectedTickets.total_tickets" placeholder="e.g VIP">
                                </div>
    
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h4>Group Ticket</h4>
                                    <input type="number" v-model="selectedTickets.group_ticket_quantity">
                                </div>
                                <div class="col-6">
                                    <h4>Complimentary</h4>
                                    <input type="number" v-model="selectedTickets.total_complimentary">
                                </div>
                            </div>
                            <div class="col">
                                <h4>Amount</h4>
                                <input type="number" v-model="selectedTickets.amount" disabled>
                            </div>
                            <div class="col">
                                <h3>--------- Ticket Colors ----------</h3>
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h5>Base Color</h5>
                                    <input type="color" id="favcolor"v-model="selectedTickets.main_color_code" name="favcolor" style="padding: 0px;">
                                </div>
                                <div class="col-6">
                                    <h5>Secondary Color</h5>
                                    <input type="color" id="favcolor"v-model="selectedTickets.color_code" name="favcolor" style="padding: 0px;">
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
    
    
            </vue-modal-2>
            <div class="row">
                <vue-modal-2 width="500" name="modal_complimentary" @on-close="closeComplimentary" :headerOptions="{
                            title: 'Send Complimentary: '+selectedTickets.ticket_type,
                        }" :footerOptions="{
                            btn1: 'Cancel',
                            btn2: 'Send Complimentary',
                            btn1Style: {
                            backgroundColor: 'red',
                            color:'white'
                            },
                            btn2OnClick: () => {
                                sendComplimentary();
                            },
                            btn1OnClick: () => {
                            $vm2.close('modal_complimentary');
                            },
                        }">
                    <div class="row">
    
                        <div class="l-form row">
                            <div class="col">
                                <div class="col-6">
                                    <h4>Phones</h4>
                                    <input type="tel" v-model="comp_msisdn" placeholder="254700000000">
                                </div>
                                <div class="col-6">
                                    <h4>Name</h4>
                                    <input type="tel" v-model="comp_name" placeholder="e.g John Doe">
                                </div>
    
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h4>Company</h4>
                                    <input type="tel" v-model="comp_company" placeholder="e.g madfun">
                                </div>
                                <div class="col-6">
                                    <h4>Quantity</h4>
                                    <input type="tel" v-model="comp_quantity">
                                </div>
                            </div>
                            <div class="col">
                                <h4>Email</h4>
                                <input type="email" v-model="comp_email" placeholder="e.g name@mail.com">
                            </div>
                        </div>
    
                    </div>
    
                </vue-modal-2>
            </div>
    
        </div>
    </div>
</template>

<script>
import Chart from "chart.js";
import store from "../store";
import $ from 'jquery';
import numeral from "numeral";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import VueTagsInput from '@johmun/vue-tags-input';
import { ColourPicker } from "vue-colour-picker"

export default {
    data() {
        return {
            base_colour: '#F6D02A',
            second_colour: '#B59404',
            comp_msisdn: "",
            comp_name: "",
            comp_company: "",
            comp_email: "",
            comp_quantity: 1,
            selectedTab: 'summary',
            event_show_id: "",
            tags: [],
            showName: "",
            tag: "",
            event_show_venue_id: "",
            eventData: {
                eventName: "",
                company: "",
                venue: "",
                eventID: "",
                posterURL: "",
                hasMultipleShow: "",
                isPublic: "",
                aboutEvent: "",
                status: "",
                start_date: "",
                end_date: "",
                category_id: ""
            },
            eventRange: [],
            eventShows: [],
            eventShowsVenue: [],
            totalTickets: 0,
            totalPurchase: [],
            days: 7,
            total_complimentary: 0,
            groupTickets: 1,

            time3: "",
            startDate: "",
            endDate: "",
            ticketPurchase: 0,
            ticketRedmeed: 0,
            ticketTypes: [],
            amount: 0,
            selectedTickets: {
                ticket_type: "",
                event_ticket_id: "",
                group_ticket_quantity: "",
                status: "",
                total_complimentary: "",
                amount: "",
                total_tickets: "",
            },
            totalTickets: 10,
            ticketTypeSelect: "",
            errorcreateStatus: false,
            errorsMessage: "",
            ticketTypesList: [],
            amount: 0
        }
    },
    components: {
        DatePicker,
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        VueTagsInput,
        'colour-picker': ColourPicker
    },
    mounted() {

        let vm = this;
        if (!vm.$route.params.id) {
            this.$router.push({
                name: 'view-events'
            });
        }
        if (!vm.$cookies.get("selectedEvent")) {
            this.$router.push({
                name: 'view-events'
            });
        }
        vm.eventData = vm.$cookies.get("selectedEvent");
        vm.getTicketTypesList()
        if (vm.eventData.hasMultipleShow == 1) {

            vm.getEventShows();
        } else {
            vm.getsummary();
            vm.getTicketPurchase();
            vm.getTicketTypes();

        }



    },
    methods: {

        sendComplimentary() {
            let vm = this
            $.post({
                url: store.state.rootUrl + "v1/api/main/complimentary/ticket",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    event_ticket_id: vm.selectedTickets.event_ticket_id,
                    msisdn: vm.comp_msisdn,
                    name: vm.comp_name,
                    company: vm.comp_company,
                    email: vm.comp_email,
                    unique_id: Math.floor(Math.random() * (99999999999 - 1000000 + 1)) + 1000000,
                    quantity: vm.comp_quantity,
                    show: vm.eventData.hasMultipleShow,
                    source: "WEB"
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        vm.$swal('Error', 'Failed to Sent Complimentary Tickets. <br />Reason: ' + response.data.statusDescription, 'error')
                    } else {
                        vm.$swal('Sent', 'Successfully Sent Complimentary Tickets', 'success')
                        vm.closeComplimentary()
                        vm.searchRecords(vm.days)
                    }
                },
                error: function(jQxhr, status, error) {
                    vm.$swal('Failed', 'Failed to Sent Complimentary Tickets', 'error')
                    vm.close()
                }
            });

        },
        openComplimentary(props) {
            this.selectedTickets = props
            this.$vm2.open('modal_complimentary')
        },
        closeComplimentary() {
            this.$vm2.close('modal_complimentary')
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        closeShows() {
            this.$vm2.close('modal_create_shows')
        },
        viewEvent() {
            let vm = this
            window.open('https://madfun.com/event/' + vm.$route.params.id, '_blank')
        },
        createShows() {
            let vm = this
            const listArray = []

            vm.tags.forEach((element, index, array) => {
                listArray.push(element.text)
                console.log(element.text)

            });
            let venues = listArray.join(",");
            $.post({
                url: store.state.rootUrl + "v1/api/event/add/show",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    eventID: vm.$route.params.id,
                    show: vm.showName,
                    venue: venues,
                    start_date: vm.format_date(vm.eventRange[0]),
                    end_date: vm.format_date(vm.eventRange[1]),
                    source: "WEB"
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        vm.$swal('Error', 'Failed to Updated. <br />Reason: ' + response.data.statusDescription, 'error')
                    } else {
                        vm.$swal('Sent', 'Successfully Updated event ticket Type', 'success')
                        vm.closeShows()
                        vm.searchRecords(vm.days)
                    }
                },
                error: function(jQxhr, status, error) {
                    vm.$swal('Failed', 'Failed Updated event ticket Type', 'error')
                    vm.close()
                }
            });
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        close() {
            this.$vm2.close('modal_edit')
        },
        openEditTickets(props) {
            this.selectedTickets = props
            this.$vm2.open('modal_edit')

        },
        openCreateShows() {
            this.$vm2.open('modal_create_shows')
        },
        searchRecords(v) {
            let vm = this;
            if (vm.time3 != "") {
                vm.days = 0;
                var timString = vm.time3 + "";
                var arrayTime = timString.split(",");
                vm.startDate = vm.format_date(arrayTime[0]) + " 00:00:00";
                vm.endDate = vm.format_date(arrayTime[1]) + " 23:59:59";

            } else {
                vm.days = v;
            }
            vm.getTicketPurchase();

        },
        addTicketType() {
            this.$modal.show('example')
        },
        updateTickets() {
            let vm = this
            $.post({
                url: store.state.rootUrl + "v1/api/event/type/edit",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    typeId: vm.selectedTickets.typeId,
                    event_ticket_id: vm.selectedTickets.event_ticket_id,
                    amount: vm.selectedTickets.amount,
                    color_code: vm.selectedTickets.color_code,
                    main_color_code: vm.selectedTickets.main_color_code,
                    total_tickets: vm.selectedTickets.total_tickets,
                    groupTickets: vm.selectedTickets.group_ticket_quantity,
                    total_complimentary: vm.selectedTickets.total_complimentary,
                    event_show_venue_id: vm.event_show_venue_id,
                    status: vm.selectedTickets.status,
                    source: "WEB"
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        vm.$swal('Error', 'Failed to Updated. <br />Reason: ' + response.data.statusDescription, 'error')
                    } else {
                        vm.$swal('Sent', 'Successfully Updated event ticket Type', 'success')
                        vm.close()
                        vm.searchRecords(vm.days)
                    }
                },
                error: function(jQxhr, status, error) {
                    vm.$swal('Failed', 'Failed Updated event ticket Type', 'error')
                    vm.close()
                }
            });
        },
        createEventTicketTypes() {
            let vm = this;
            vm.$swal({
                title: 'Are you sure?',
                text: 'You want to create event ticket Type ',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, create it!',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    $.post({
                        url: store.state.rootUrl + "v1/api/event/type/create",
                        type: "POST",
                        data: JSON.stringify({
                            api_key: vm.$cookies.get("key"),
                            typeId: vm.ticketTypeSelect,
                            eventId: vm.$route.params.id,
                            amount: vm.amount,
                            total_tickets: vm.totalTickets,
                            groupTickets: vm.groupTickets,
                            total_complimentary: vm.total_complimentary,
                            event_show_venue_id: vm.event_show_venue_id,
                            source: "WEB"
                        }),
                        success: function(response, status, jQxhr) {
                            if (response.data.code != 200) {
                                vm.$swal('Error', 'Failed to create. <br />Reason: ' + response.data.statusDescription, 'error')
                            } else {
                                vm.$swal('Sent', 'Successfully created event ticket Type', 'success')
                                vm.$modal.hide('example')
                                vm.searchRecords(vm.days)
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.$swal('Failed', 'Failed created event ticket Type', 'error')
                            vm.$modal.hide('example')
                        }
                    });

                } else {
                    vm.$swal('Cancelled', 'Cancel to created event ticket Type', 'info')
                    vm.$modal.hide('example')
                }
            })

        },
        getEventShows() {
            let vm = this
            $.post({
                url: store.state.rootUrl + "v1/api/event/view/show",
                type: "POST",
                data: JSON.stringify({
                    api_key: "4ba0e1aae090cdefc1887d2689b25e3f",
                    eventID: vm.$route.params.id,
                    source: 'WEB'
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code == 200) {
                        vm.eventShows = response.data.data;
                        vm.event_show_id = response.data.data[0].event_show_id;
                        vm.getEventShowsVenue(response.data.data[0].event_show_id)

                    } else {
                        vm.eventShows = []
                    }

                },
                error: function(jQxhr, status, error) {
                    vm.eventShows = []
                }
            });
        },
        getEventShowsVenue(event_show_id) {
            let vm = this
            $.post({
                url: store.state.rootUrl + "v1/api/event/view/show/venue",
                type: "POST",
                data: JSON.stringify({
                    api_key: "4ba0e1aae090cdefc1887d2689b25e3f",
                    event_show_id: event_show_id,
                    source: 'WEB'
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code == 200) {
                        vm.event_show_venue_id = response.data.data[0].event_show_venue_id
                        vm.eventShowsVenue = response.data.data;
                        vm.getsummary();
                        vm.getTicketPurchase();
                        vm.getTicketTypes()

                    } else {
                        vm.eventShowsVenue = []
                    }

                },
                error: function(jQxhr, status, error) {
                    vm.eventShowsVenue = []
                }
            });
        },
        getsummary() {
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/events/summary",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    eventID: vm.$route.params.id,
                    event_show_venue_id: vm.event_show_venue_id,
                    source: 'WEB'
                }),
                success: function(response, status, jQxhr) {
                    var res = response.data.data;
                    vm.totalTickets = Number(response.data.data[0].allTickets);
                    vm.ticketPurchase = Number(response.data.data[0].purchased);
                    vm.ticketRedmeed = Number(response.data.data[0].redeemed);
                    vm.amount = Number(response.data.data[0].totalAmountEvent);
                },
                error: function(jQxhr, status, error) {

                }
            });
        },
        getTicketPurchase() {
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/tickets/graph",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    days: vm.days,
                    start: vm.startDate,
                    end: vm.endDate,
                    eventID: vm.$route.params.id,
                    event_show_venue_id: vm.event_show_venue_id
                }),
                success: function(response, status, jQxhr) {
                    let yData = [];
                    let xDate = [];
                    let result = response.data.data;

                    result.forEach(function(data) {
                        yData.push(data["COUNT"]);
                        xDate.push(data["Date"]);
                    });
                    var ctx = $('#purchase-chart');
                    var customersChart = new Chart(ctx, {
                        type: 'bar',
                        data: {
                            datasets: [{
                                data: yData,
                                backgroundColor: '#FFA500'
                            }],
                            labels: xDate,
                        },
                        options: {
                            maintainAspectRatio: false,
                            responsive: true,
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }
                    });
                },
                error: function(jQxhr, status, error) {}
            });
        },
        getTicketTypes() {
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/tickets/types",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    days: vm.days,
                    start: vm.startDate,
                    end: vm.endDate,
                    eventID: vm.$route.params.id,
                    event_show_venue_id: vm.event_show_venue_id,
                    source: 'WEB',

                }),
                success: function(response, status, jQxhr) {
                    var res = response.data.data;
                    vm.ticketTypes = res
                },
                error: function(jQxhr, status, error) {

                }
            });
        },
        getTicketTypesList() {
            let vm = this
            //https://api.v1.interactive.madfun.com/v1/api/event/ticket/type/dashboard/view

            $.post({
                url: store.state.rootUrl + "v1/api/event/ticket/type/dashboard/view?per_page=100",
                type: "GET",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    limit: 30
                }),
                success: function(response, status, jQxhr) {
                    var res = response.data;
                    vm.ticketTypesList = res
                },
                error: function(jQxhr, status, error) {

                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    filters: {
        formatAmount(value) {
            return numeral(value).format("0,0.00");
        },
        formatPercent(value) {
            return numeral(value).format("0,0.00");
        },
        formatNumber(value) {
            return numeral(value).format("0,0");
        },
        formatStatus(value) {
            if (value != null) {
                if (value == 1) {
                    return "Active"
                } else if (value == 2) {
                    return "Cancel"
                } else if (value == 3) {
                    return "Sold Out"
                } else {
                    return "Pending"
                }

            }
            return ""
        }

    }
}
</script>
